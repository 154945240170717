import shopifyEmpireGenericDefaults from '../shopify-empire-generic/config.js';

window.Convermax.fitmentFieldsTranslation = {
  Year: 'Año',
  Make: 'Marca',
  Model: 'Modelo',
  Engine: 'Motor',
};

const getFitmentSearchTitle = () => {
  return 'Productos para:';
};

export default {
  includes: ['shopify-empire-generic'],
  ...shopifyEmpireGenericDefaults,
  fitmentSearch: {
    ...shopifyEmpireGenericDefaults.fitmentSearch,
    getFitmentSearchTitle,
  },
  Widgets: [
    ...shopifyEmpireGenericDefaults.Widgets.filter((w) => !['SearchHeader'].includes(w.name)),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Mejor coincidencia',
        'title': 'Nombre, A-Z',
        'title:desc': 'Nombre, Z-A',
        'collection_sort': 'Destacados',
        'price': 'Precio, de menor a mayor',
        'price:desc': 'Precio, de mayor a menor',
        'published_at': 'Fecha, de antigua a reciente',
        'published_at:desc': 'Fecha, de reciente a antigua',
      },
    },
  ],
};
