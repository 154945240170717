export default {
  SEARCH_BOX_PLACEHOLDER: '{window.Convermax.config?.searchBoxPlaceholderText || "Busca"}',
  SEARCH_BOX_TITLE: 'Busca',
  SEARCH_BOX_SECTION_TITLE:
    "<b>{this.totalHits > 1 ? `${this.totalHits} ` : ''}{{sectionTitle}}</b>{!!this.vehicleString ? ` per ${this.vehicleString}` : ''}",
  BROWSE_ALL: 'Ver todos {{totalHits}} productos',

  PRICE_FACET_GO_TEXT: 'IR',

  YOU_MAY_ALSO_LIKE: 'Tal vez te guste...',
  NO_RESULTS: 'No hay resultados',

  CANCEL_TEXT: 'Cancelar',
  SORT_BY: 'Ordenar por:&nbsp;',
  TOTAL_HITS: 'resultados',
  START_OVER: 'Reiniciar',
  SHOW_LESS: 'Mostrar menos',
  SHOW_MORE: 'Mostrar más',
  MAX_PRICE: 'Máximo',
  MIN_PRICE: 'Mínimo',
  CURRENT_SEARCH: 'Búsqueda actual:',
  FACET_FILTER_PLACEHOLDER: 'Busca',
  FACET_FILTER_NOTHING:
    '¿No encontraste el repuesto indicado? Llámanos al 2101-5631 y uno de nuestros agentes te atenderá.',

  FILTERS_TEXT: 'Filtros',
  FACET_DIALOG_TITLE: 'Filtros',
  FACET_DIALOG_RESULTS: 'Resultados',
  FACET_DIALOG_CLEAR: 'Borrar',
  FACET_DIALOG_DONE: 'Listo',
  FACET_DIALOG_VEHICLE_SPECS_TITLE: '<span>Especificaciones del vehículo</span>',
  FACET_DIALOG_VEHICLE_SPECS_RESET: 'Restablecer especificaciones',

  FILTER_SEARCH_BOX_PLACEHOLDER:
    "Buscar dentro de {this.pageType === 'category' ? 'Categoría' :" +
    "this.pageType === 'brand' ? 'Marca' : 'Resultados'}",

  SEARCH_WITHIN: 'buscar dentro',
  SHOW_RESULTS: 'Mostrar {{totalHits}} resultados',

  VIEW_TOGGLE_NAME: 'Ver',

  VEHICLE_TABS_TITLE_PREPEND: 'Buscar por',
  MORE_LIKE_THIS: 'Más como este',

  SELECT_CUSTOM_COLOR: 'Selecciona un color',
  SELECT_EXTRACTED_COLOR_TITLE: 'Selecciona color(es)',
  SELECT_EXTRACTED_COLOR: 'Por favor, elige color(es) de tu imagen:',

  UPLOAD_YOUR_COLOR: 'Sube tu color',

  CHANGE_TEXT: 'Cambiar',
  CHANGE_VEHICLE: 'Cambiar vehículo',
  GO_TEXT: 'IR',
  CLEAR_TEXT: 'Borrar',
  CLEAR_GARAGE_TEXT: 'Vaciar garaje',
  DISCARD_VEHICLE: 'Borrar selección',
  ADD_VEHICLE: "{window.Convermax.config?.garageButtonText || 'Agregar un vehículo'}",
  FIND_PARTS_YOU_NEED: 'Encuentra fácilmente las piezas que necesitas',

  FITMENT_TABLE_MANY_FITMENTS:
    'Actualmente mostrando 1000 adaptaciones, pero el producto tiene más disponibles.',

  GET_PRODUCTS_FOR_VEHICLE: 'Obtener productos para tu vehículo',
  STORE_VEHICLE_IN_GARAGE: 'Guardar tu vehículo en el garaje',

  SELECT_YOUR_VEHICLE: 'Selecciona tu vehículo',
  SELECT_YOUR_VEHICLE_BLOCK: 'Selecciona tu <span>vehículo</span>',

  VERIFY_FITMENT_TITLE: 'Verificar compatibilidad con tu vehículo',
  VERIFY_FITMENT_FITS: 'Este producto es compatible con tu',
  VERIFY_FITMENT_NOT_FIT: 'Este producto NO es compatible con tu',
  VERIFY_FITMENT_SPECIFY: "Por favor, especifica {{fieldsToSpecify.join(', ')}} para tu {{vehicleString}}",
  FITMENT_UNIVERSAL_TITLE:
    "{window.Convermax.config?.verifyFitmentUniversalTitle || 'Compatibilidad universal'}",
  FITMENT_UNIVERSAL_TEXT: 'Este producto podría requerir modificaciones.',
  FITMENT_UNKNOWN_TITLE: 'Sin datos de compatibilidad',
  FITMENT_UNKNOWN_TEXT:
    'Este producto no tiene datos de aplicación compatibles. Verifica la compatibilidad manualmente.',
  VERIFY_FITMENT_COMPATIBLE_PARTS: 'Haz clic aquí para ver productos compatibles con tu vehículo',

  SELECT_TITLE: "{window.Convermax.fitmentFieldsTranslation[this.title.replaceAll(' ', '_')] || this.title}",
  SELECT_LOADING: '...Cargando...',

  POPULAR_VALUES: 'Marcas populares',
  UNPOPULAR_VALUES: 'Todas las marcas',

  PRICE_FACET_MORE: 'y más',
  PRICE_FACET_LESS: 'y menos',
  SELECTED_CUSTOM_RANGE_TO: 'hasta',

  LOAD_MORE_TEXT: '{window.Convermax.config?.loadMoreText || `Siguientes ${this.nextPageSize}`}',
};
