
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatBadge1(badge, badgeIndex) {
                    return _createElement('span', {
                        'className': 'productitem__badge productitem__badge--soldout cm_badge cm_badge__' + this.toKebabCase(badge),
                        'key': badge
                    }, '\n                ', badge, '\n              ');
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('li', {
                    'className': 'imagestyle--medium productgrid--item productitem--emphasis show-actions--mobile' + (this.on_sale ? ' productitem--sale' : ''),
                    'data-product-item': true,
                    'data-product-quickshop-url': this.url
                }, _createElement('div', {
                    'className': 'productitem',
                    'data-product-item-content': true
                }, _createElement('div', { 'className': 'productitem__container' }, _createElement('div', { 'className': 'productitem__image-container' }, _createElement('a', {
                    'className': 'productitem--image-link',
                    'href': this.url,
                    'data-product-page-link': true
                }, _createElement('figure', {
                    'className': 'productitem--image',
                    'data-product-item-image': true
                }, this.image2 ? _createElement('img', {
                    'className': 'productitem--image-alternate',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '536'
                }) : null, _createElement('img', {
                    'className': 'productitem--image-primary',
                    'alt': this.removeHTML(this.title),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'onError': this.onImageError
                }), _createElement.apply(this, [
                    'div',
                    { 'className': 'productitem__badge productitem__badge-container' },
                    this.out_of_stock ? _createElement('span', {
                        'className': 'productitem__badge productitem__badge--soldout',
                        'key': '1001'
                    }, '\n                Agotado\n              ') : null,
                    !this.out_of_stock && this.on_sale ? _createElement('span', {
                        'className': 'productitem__badge productitem__badge--sale',
                        'key': '1149'
                    }, _createElement('span', { 'data-badge-sales-range': true }, '\n                  Ahorra\n                  ', _createElement('span', {
                        'className': 'money',
                        'data-price-money-saved': true
                    }, '\n                    ', this.formatPrice(this.compare_at_price - this.price), '\n                  '))) : null,
                    this._badges ? _map(this._badges, repeatBadge1.bind(this)) : null
                ])))), _createElement('div', { 'className': 'productitem--info' }, _createElement('div', { 'className': 'price productitem__price' }, _createElement('div', { 'className': 'price__compare-at' }, _createElement('span', { 'className': 'money price__compare-at--single' }, this.formatPrice(this.compare_at_price))), _createElement('div', { 'className': 'price__current price__current--emphasize' + (this.on_sale ? ' price__current--on-sale' : '') }, this.price_varies ? _createElement('span', { 'key': '2242' }, 'From: ') : null, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price))), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price__current--set',
                    'key': '2385'
                }, '\n            Set de ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n          ') : null), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '25910'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], _createElement('h2', { 'className': 'productitem--title' }, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'data-product-page-link': true
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), [_createElement('div', {
                        'className': 'cm_verify-fitment-badge-container',
                        'key': '31690'
                    }, window.Convermax.isVehicleSelected() && this.Fitment === 'Vehicle Specific' && !window.Convermax.getSearchResponse()?.messages?.includes('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_verify-fitment-badge cm_badge-yes',
                            'key': '32311'
                        }, _createElement('div', { 'className': 'cm_verify-fitment cm_vehicle-widget__verify-fitment' }, _createElement('div', { 'className': 'cm_vehicle-widget_label cm_verify-fitment_label cm_verify-fitment__yes' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, [_createElement('svg', {
                                'className': 'icon fits-yes',
                                'width': '12',
                                'height': '12',
                                'viewBox': '0 0 19 21',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '37320'
                            }, _createElement('path', {
                                'fillRule': 'evenodd',
                                'clipRule': 'evenodd',
                                'd': 'M10 0.5C4.5 0.5 0 5 0 10.5C0 16 4.5 20.5 10 20.5C15.5 20.5 20 16 20 10.5C20 5 15.5 0.5 10 0.5ZM8 15.5L3 10.5L4.4 9.1L8 12.7L15.6 5.1L17 6.5L8 15.5Z',
                                'fill': '#349B3E'
                            }))], _createElement('div', {}, _createElement('span', {}, 'Ajuste exacto'), ' para tu', _createElement('br', {}), window.Convermax.getVehicleString())))))] : null, this.Fitment === 'Universal Fit' ? [_createElement('div', {
                            'className': 'cm_verify-fitment-badge cm_badge-universal',
                            'key': '42721'
                        }, _createElement('div', { 'className': 'cm_verify-fitment cm_vehicle-widget__verify-fitment' }, _createElement('div', { 'className': 'cm_vehicle-widget_label cm_verify-fitment_label cm_verify-fitment__universal' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, [_createElement('svg', {
                                'className': 'icon fits-universal',
                                'width': '12',
                                'height': '12',
                                'viewBox': '0 0 20 20',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '46280'
                            }, _createElement('path', {
                                'fillRule': 'evenodd',
                                'clipRule': 'evenodd',
                                'd': 'M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z',
                                'fill': '#00adef'
                            }))], _createElement('span', {}, 'Ajuste universal')))))] : null, this.Fitment === 'No Fitment Data' ? [_createElement('div', {
                            'className': 'cm_verify-fitment-badge cm_badge-unknown',
                            'key': '50751'
                        }, _createElement('div', { 'className': 'cm_verify-fitment cm_vehicle-widget__verify-fitment' }, _createElement('div', { 'className': 'cm_vehicle-widget_label cm_verify-fitment_label cm_verify-fitment__unknown' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, [_createElement('svg', {
                                'className': 'icon fits-unknown',
                                'width': '15',
                                'height': '15',
                                'viewBox': '0 0 20 20',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '54290'
                            }, _createElement('path', {
                                'fillRule': 'evenodd',
                                'clipRule': 'evenodd',
                                'd': 'M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z',
                                'fill': '#f29508'
                            }))], _createElement('span', {}, 'Sin datos de ajuste')))))] : null)], _createElement('div', { 'className': 'productitem__stock-level' }, _createElement('div', { 'className': 'product-stock-level-wrapper' }, this.inventory_quantity && !this.out_of_stock ? _createElement('span', {
                    'className': 'product-stock-level' + (this.inventory_quantity < 5 ? ' product-stock-level--low' : ' product-stock-level--high'),
                    'key': '6007'
                }, _createElement('span', { 'className': 'product-stock-level__text' }, _createElement('div', { 'className': 'product-stock-level__badge-text' }, '\n                  ', this.inventory_quantity < 5 ? `¡Solo quedan ${ this.inventory_quantity }!` : 'En stock', '\n                '))) : null, this.out_of_stock ? _createElement('span', {
                    'className': 'product-stock-level product-stock-level--none',
                    'key': '6540'
                }, _createElement('span', { 'className': 'product-stock-level__text' }, _createElement('div', { 'className': 'product-stock-level__badge-text' }, 'Agotado'))) : null))), _createElement('div', {
                    'className': 'productitem--actions',
                    'data-product-actions': true
                }, _createElement('div', { 'className': 'productitem--action quickshop-button' }, _createElement('button', {
                    'onClick': window.Convermax.quickView(this),
                    'className': 'productitem--action-trigger button-secondary mdc-ripple-surface mdc-ripple-upgraded',
                    'data-quickshop-full': true,
                    'type': 'button'
                }, '\n            Compra rápida\n          ')), _createElement('div', { 'className': 'productitem--action atc--button' }, this.variant_ids.length === 1 && !this.out_of_stock ? _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'encType': 'multipart/form-data',
                    'key': '7291'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': this.set_qty || 1
                }), _createElement('button', {
                    'className': 'button-primary mdc-ripple-surface mdc-ripple-upgraded productitem--action-atc productitem--action-trigger',
                    'type': 'submit',
                    'aria-label': 'Add to cart'
                }, _createElement('span', { 'className': 'atc-button--text' }, 'Agregar', this.set_qty ? ` Set de ${ this.set_qty }` : ''))) : null, this.variant_ids.length > 1 && !this.out_of_stock ? _createElement('a', {
                    'href': this.url,
                    'className': 'button-primary mdc-ripple-surface mdc-ripple-upgraded productitem--action-atc productitem--action-trigger',
                    'key': '7926'
                }, _createElement('span', { 'className': 'atc-button--text' }, 'Elegir opciones')) : null, this.out_of_stock ? _createElement('button', {
                    'className': 'button-secondary mdc-ripple-surface mdc-ripple-upgraded productitem--action-atc productitem--action-trigger',
                    'aria-label': 'Out of stock',
                    'disabled': true,
                    'key': '8222'
                }, _createElement('span', { 'className': 'atc-button--text' }, 'Agotado')) : null)), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '8523'
                }) : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'product-section--title product-recommendations--title cm_related-products-title' }, 'Tal vez te guste...')), _createElement.apply(this, [
        'div',
        { 'className': 'product-section--content product-row cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []